import React from "react";

// Components
import PostList, { PostListProps } from "./PostList";
import Typography from "@material-ui/core/Typography";

// Styles
import { createStyles, withStyles } from "@material-ui/core/styles";

// Types
import { StandardProps } from "@material-ui/core";

const styles = createStyles({
  title: {},
  list: {},
});

export type RelatedPostsClassKey = "title" | "list";
export type RelatedPostsProps = StandardProps<
  {
    posts: PostListProps["posts"];
  },
  RelatedPostsClassKey
>;

function RelatedPosts({ posts, classes }: RelatedPostsProps): JSX.Element {
  if (posts.length === 0) {
    return <></>;
  }

  return (
    <>
      <Typography
        className={classes?.title}
        variant="h4"
        align="center"
        gutterBottom
      >
        D'autres articles pourraient vous intéresser
      </Typography>
      <PostList className={classes?.list} posts={posts} />
    </>
  );
}

export default withStyles(styles)(RelatedPosts);
