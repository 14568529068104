import React from "react";

// Gatsby
import Img, { FluidObject } from "gatsby-image";

// Components
import BackButton from "../../Buttons/BackButton";
import Typography from "@material-ui/core/Typography";
import TagList from "../TagList";

// Styles
import clsx from "clsx";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

// Types
import { StandardProps } from "@material-ui/core";

export type HeaderClassKey = "root" | "title" | "tags" | "cover" | "backButton";

export type HeaderProps = {
  /** Tags of the post */
  readonly tags: string[];

  /** Title of the post */
  readonly title: string;

  /** Image leading the post */
  readonly cover: FluidObject;

  /** Date of the post */
  readonly date: string;
} & StandardProps<React.HTMLProps<HTMLElement>, HeaderClassKey>;

function Header({
  tags,
  title,
  cover,
  date,
  classes,
  className,
  ...sectionProps
}: HeaderProps): JSX.Element {
  const dateStr = React.useMemo<string>(
    () =>
      new Date(date).toLocaleDateString("fr", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }),
    [date]
  );

  return (
    <>
      <section {...sectionProps} className={clsx(classes?.root, className)}>
        <BackButton className={classes?.backButton} />
        <Typography variant="h1" className={classes?.title}>
          {title}
        </Typography>
        <div>
          <TagList tags={tags} className={classes?.tags} />
          <Typography
            component="span"
            variant="subtitle1"
            color="textSecondary"
          >
            {dateStr}
          </Typography>
        </div>
        <Img fluid={cover} className={classes?.cover} alt={title} />
      </section>
    </>
  );
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      textAlign: "center",
    },
    backButton: {
      display: "flex",
    },
    title: {
      fontWeight: 500,
      fontSize: 58,
      lineHeight: "68px",
      letterSpacing: -2,
      color: "#262a3e",

      [theme.breakpoints.down("md")]: {
        fontSize: 48,
      },
    },
    tags: {
      justifyContent: "center",
    },
    cover: {
      maxHeight: 500,
      margin: "auto", // center in case width < max
    },
  });

export default withStyles(styles)(Header);
