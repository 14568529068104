import React from "react";

// Styles
import "./styles.css";

export type ContentProps = {
  /** HTML to inject into the content */
  html: string;
} & React.HTMLProps<HTMLElement>;

const Content = ({ html, ...articleProps }: ContentProps): JSX.Element => {
  return (
    <article
      {...articleProps}
      className="prose prose-lg lg:prose-xl max-w-3xl mx-auto text-body"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};

export default Content;
