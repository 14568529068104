import React from "react";

// Components
import Button, { ButtonProps } from "@material-ui/core/Button";

// Icons
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

// Helpers
import { navigate } from "@reach/router";

export interface BackButtonProps extends ButtonProps {}

export const BackButton = ({ ...props }: BackButtonProps): JSX.Element => {
  return (
    <Button
      onClick={() => (history.length > 2 ? navigate(-1) : navigate("/"))}
      startIcon={<ArrowBackIcon />}
      variant="text"
      {...props}
    >
      Retour
    </Button>
  );
};

export default BackButton;
