import React from "react";

// Components
import Typography from "@material-ui/core/Typography";
import SubscriptionInput from "../../Newsletter/SubscriptionInput";

// Styles
import { withStyles } from "@material-ui/core/styles";

export type ArticleNotReleasedProps = {
  date: Date;
};

const ArticleNotReleased = ({ date }: ArticleNotReleasedProps): JSX.Element => {
  return (
    <section className="prose prose-lg lg:prose-xl max-w-3xl mx-auto text-body">
      <center>
        <Typography variant="h3">
          Vous pourrez découvrir comment eKee répond à cette problématique{" "}
          {date.toLocaleDateString("fr", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
        <Typography
          align="center"
          variant="h6"
          component="label"
          color="textSecondary"
          style={{ marginTop: -16 }}
        >
          Être notifié⸱e dès que l'article est disponible
          <StyledSubscriptionInput />
        </Typography>
      </center>
    </section>
  );
};

const StyledSubscriptionInput = withStyles((theme) => ({
  container: {
    justifyContent: "center",
    padding: theme.spacing(1),
  },
  input: {
    background: theme.convexSvg.grey,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: "min(520px, 80%)",
  },
  button: {
    color: theme.palette.text.primary,
  },
}))(SubscriptionInput);

export default ArticleNotReleased;
