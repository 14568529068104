import React from "react";

// Components
import Typography from "@material-ui/core/Typography";
import SubscriptionInput from "../../Newsletter/SubscriptionInput";

// Styles
import { withStyles } from "@material-ui/core/styles";

export type ArticleNotReleasedProps = {} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
>;

const ArticleNotReleased = (props: ArticleNotReleasedProps): JSX.Element => {
  return (
    <section
      className="prose prose-lg lg:prose-xl max-w-3xl mx-auto text-body"
      {...props}
    >
      <center>
        <Typography variant="h5">L'article vous a plu ? </Typography>
        <Typography
          align="center"
          variant="h6"
          component="label"
          color="textSecondary"
          style={{ marginTop: -16 }}
        >
          Être notifié⸱e dès qu'un nouvel article est disponible
          <StyledSubscriptionInput />
        </Typography>
      </center>
    </section>
  );
};

const StyledSubscriptionInput = withStyles((theme) => ({
  container: {
    justifyContent: "center",
    padding: theme.spacing(1),
  },
  input: {
    background: theme.convexSvg.grey,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minWidth: "min(520px, 80%)",
  },
  button: {
    color: theme.palette.text.primary,
  },
}))(SubscriptionInput);

export default ArticleNotReleased;
