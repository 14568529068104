import React from "react";

// Gatsby
import { graphql } from "gatsby";
import PropTypes from "prop-types";

// Components
import Article from "../components/Blog/PostContent/Article";
import ArticleNotReleased from "../components/Blog/PostContent/ArticleNotReleased";
import SubsribeToNewArticles from "../components/Blog/PostContent/SubsribeToNewArticles";
import AuthorCard from "../components/Blog/AuthorCard";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Header from "../components/Blog/PostContent/Header";
import RelatedPosts from "../components/Blog/RelatedPosts";
import SEO from "../components/SEO";

// Styles
import makeStyles from "@material-ui/core/styles/makeStyles";
import { FluidObject } from "gatsby-image";

const useClasses = makeStyles((theme) => ({
  header: {
    marginBottom: theme.spacing(6),
  },
  article: {},
  links: {},
  divider: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
  aboutAuthor: {},
  author: {
    marginBottom: theme.spacing(2),
    "& .MuiAvatar-root": {
      boxShadow:
        "0 5px 15px 0 rgba(37,44,97,.15),0 2px 4px 0 rgba(93,100,148,.2)",
    },
  },
  relatedPostsTitle: {
    marginTop: theme.spacing(10),
    wordBreak: "break-word",
    maxWidth: 768,
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: 40,
    fontWeight: 500,
    color: "#262a3e",
  },
  subscribeToNewArticles: {
    margin: `${theme.spacing(4)}px 0`,
  },
  relatedPostsList: {
    marginBottom: theme.spacing(10),
  },
}));

const Post = ({ data }: any): JSX.Element => {
  const classes = useClasses();

  const { edges } = data.allMarkdownRemark;
  const { html, frontmatter, excerpt } = data.markdownRemark;
  const { date, title, tags, path, description, author } = frontmatter;
  const image: FluidObject = frontmatter.cover.childImageSharp.fluid;

  const releaseDate = new Date(date);
  const now = new Date();

  return (
    <>
      <SEO
        article
        title={title}
        description={description || excerpt || " "}
        banner={image}
        pathname={path}
        siteMdExt={{
          dateCreated: frontmatter.date,
          datePublished: frontmatter.date,
          author: `${
            frontmatter.author.firstname
          } ${frontmatter.author.lastname.toLocaleUpperCase()}`,
        }}
      />
      <Container maxWidth="md" component="main">
        <Header
          className={classes.header}
          title={title}
          date={date}
          tags={tags}
          cover={image}
        />
        <Article html={html} />
        {releaseDate > now ? <ArticleNotReleased date={releaseDate} /> : null}
        <Divider className={classes.divider} />
        <AuthorCard
          showSocialNetworks
          component="section"
          className={classes.author}
          author={author}
        />
        {releaseDate <= now ? (
          <SubsribeToNewArticles className={classes.subscribeToNewArticles} />
        ) : null}
        <RelatedPosts
          posts={edges}
          classes={{
            list: classes.relatedPostsList,
            title: classes.relatedPostsTitle,
          }}
        />
      </Container>
    </>
  );
};

export default Post;

Post.propTypes = {
  pageContext: PropTypes.shape({
    relatedPosts: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($pathSlug: String!, $relatedPosts: [String]) {
    allMarkdownRemark(
      filter: { frontmatter: { path: { in: $relatedPosts } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 110)
          frontmatter {
            title
            path
            tags
            date(formatString: "MM.DD.YYYY")
            description
            author {
              firstname
              lastname
              jobTitle
              linkedin
              github
              twitter
              profilePic {
                childImageSharp {
                  fixed(width: 44, height: 44) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 400
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      excerpt(pruneLength: 110)
      frontmatter {
        date
        title
        tags
        path
        author {
          firstname
          lastname
          jobTitle
          linkedin
          github
          twitter
          profilePic {
            childImageSharp {
              fixed(width: 88, height: 88) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
        cover {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            resize(height: 500, quality: 90) {
              src
            }
          }
        }
      }
    }
  }
`;
